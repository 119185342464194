<template>
  <div class="flex flex-col gap-3 text-sm text-gray-600">
    <b-tooltip position="is-bottom" multilined type="is-light">
      <div class="text-left">
        <fw-label marginless>Dias disponíveis</fw-label>

        <div class="flex gap-1 items-center">
          <div>{{ userMap.days_count.selected }} / {{ userMap.days_count.total }} dias</div>
          <div v-if="userMap.days_count.selected === userMap.days_count.total">
            <fw-icon-checkbox-circle class="w-4 h-4 flex-shrink-0 text-primary" />
          </div>
        </div>
      </div>

      <template v-slot:content>
        <div class="text-left">
          <fw-label marginless>Dias selecionados</fw-label>
          <div class="flex gap-1 items-center">
            <div>{{ userMap.days_count.selected }} {{ userMap.days_count.selected === 1 ? 'dia' : 'dias' }}</div>
            <div v-if="userMap.days_count.selected === userMap.days_count.total">
              <fw-icon-checkbox-circle class="w-4 h-4 flex-shrink-0 text-primary" />
            </div>
          </div>
        </div>

        <div class="text-left">
          <fw-label marginless>Dias disponíveis</fw-label>
          <div>{{ userMap.days_count.total }} dias</div>
        </div>
        <!-- <div class="text-left">
          <fw-label marginless>Dias a que tem direito (ano)</fw-label>
          <div>{{ userMap.days_count.csv_total }} dias</div>
        </div> -->
        <div class="text-left">
          <fw-label marginless>Dias cumulados (ano anterior)</fw-label>
          <div>{{ userMap.days_count.csv_compensated }} dias</div>
        </div>
      </template>
    </b-tooltip>

    <b-tooltip v-if="userMap.days_count.csv_credit > 0" position="is-bottom" multilined type="is-light">
      <div class="text-left">
        <fw-label marginless>Dias de crédito anual</fw-label>

        <div class="flex gap-1 items-center">
          <div>{{ userMap.days_count.credit_selected }} / {{ userMap.days_count.csv_credit }} dias</div>
          <div v-if="userMap.days_count.credit_selected === userMap.days_count.csv_credit">
            <fw-icon-checkbox-circle class="w-4 h-4 flex-shrink-0 text-primary" />
          </div>
        </div>
      </div>

      <template v-slot:content>
        <div class="text-left">
          <div>
            <fw-label marginless>Dias de crédito anual a que tem direito</fw-label>
            <div>{{ userMap.days_count.csv_credit }} dias</div>
          </div>
          <div>
            <fw-label marginless>Dias de crédito anual selecionados</fw-label>
            <div>{{ userMap.days_count.credit_selected }} dias</div>
          </div>
        </div>
      </template>
    </b-tooltip>

    <div>
      <fw-label marginless>Número <span v-if="userMap?.version_motive">e origem </span>da versão do mapa</fw-label>
      <div class="flex items-center gap-2 mt-1">
        <div v-if="userMap?.version" class="flex-shrink-0">
          <fw-tag type="light-border-box">Versão {{ userMap?.version }}</fw-tag>
        </div>
        <div v-if="userMap?.version_motive" class="text-xs">
          <div v-if="userMap?.version_motive === 'sap_sync' || userMap?.is_sap_sync">
            Versão criada pelo sistema de referência SAP RH.
          </div>
          <div v-else>{{ versionsMotives[userMap.version_motive]['pt'] }}</div>
        </div>
      </div>
    </div>

    <div v-if="userMap?.created_by && userMap?.created_by != userMap.user_key">
      <fw-label>Versão criada por</fw-label>
      <Person size="xs" :person="users[userMap?.created_by]" paddingless :name-max-lines="3" />
    </div>

    <div>
      <fw-label marginless>Nome do mapa</fw-label>
      <div>
        <a v-if="isMapManager" :href="openMapPath" class="text-primary">{{ map?.title }}</a>
        <span v-else>{{ map?.title }}</span>
      </div>
    </div>

    <div v-if="userMap?.user_key != loggedUser.key">
      <fw-label>Trabalhador</fw-label>
      <Person size="xs" :person="users[userMap?.user_key]" paddingless :name-max-lines="3" />
    </div>

    <template v-if="userMap?.version_motive == 'credit_days'">
      <div v-if="map?.manager_creditdays_key">
        <fw-label>Responsável dias de crédito</fw-label>
        <Person size="xs" :person="users?.[map.manager_creditdays_key]" paddingless :name-max-lines="3" />
      </div>

      <div v-if="map?.sub_manager_creditdays_keys?.length">
        <fw-label :counter="map.sub_manager_creditdays_keys.length"
          >Responsáveis intermédios de dias de crédito</fw-label
        >
        <template v-for="userKey in map?.sub_manager_creditdays_keys">
          <Person :key="userKey" paddingless size="xs" :person="users?.[userKey]" :name-max-lines="3" class="mb-1.5" />
        </template>
      </div>
    </template>

    <template v-else>
      <div v-if="map?.manager_key">
        <fw-label>Responsável</fw-label>
        <Person size="xs" :person="users?.[map.manager_key]" paddingless :name-max-lines="3" />
      </div>

      <div v-if="submanagers?.length">
        <fw-label :counter="submanagers.length ?? 0">Responsáveis intermédios</fw-label>
        <template v-for="userKey in submanagers">
          <Person :key="userKey" size="xs" :person="users?.[userKey]" paddingless :name-max-lines="3" class="mb-1.5" />
        </template>
      </div>
    </template>

    <div v-if="userMap?.submitted_at">
      <fw-label marginless>Versão submetida em</fw-label>
      <div>{{ userMap.submitted_at | formatDateTime }}</div>
    </div>

    <div v-if="userMap?.canceled_at">
      <fw-label marginless>Versão cancelada em</fw-label>
      <div>{{ userMap.canceled_at | formatDateTime }}</div>
    </div>

    <div v-if="userMap?.pre_approved_at">
      <fw-label marginless>Versão com parecer emitido em</fw-label>
      <div>{{ userMap.pre_approved_at | formatDateTime }}</div>
    </div>

    <div v-if="userMap?.approved_at">
      <fw-label marginless>Versão aprovada em</fw-label>
      <div>{{ userMap.approved_at | formatDateTime }}</div>
    </div>

    <div v-if="userMap?.declined_at">
      <fw-label marginless>Versão recusada em</fw-label>
      <div>{{ userMap.declined_at | formatDateTime }}</div>
    </div>
  </div>
</template>

<script>
import { MAP_USER_VERSION_REASONS } from '@/utils/index.js'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  components: {
    Person,
  },

  props: {
    userMap: {
      type: Object,
    },
    map: {
      type: Object,
    },
    users: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      versionsMotives: Object.freeze(MAP_USER_VERSION_REASONS),
    }
  },
  computed: {
    language() {
      return this.$store.state.language
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    submanagers() {
      return this.map?.sub_manager_keys?.filter(el => el != this.userMap.user_key)
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    openMapPath() {
      return this.$router.resolve({
        name: 'manage-map-users',
        params: {
          year: this.map.year,
          key: this.map.key,
        },
      }).href
    },

    isMapManager() {
      return this.map.roles?.is_map_manager || this.userPermissions.isManager || this.userPermissions.isAdmin
    },
  },
}
</script>

<style></style>
