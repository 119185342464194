var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col gap-3"},[_c('button',{staticClass:"bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium",class:{
        'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !_vm.canCreateVersion.vacations,
      },attrs:{"disabled":!_vm.canCreateVersion.vacations},on:{"click":function($event){return _vm.createVersion('vacations')}}},[_c('div',{staticClass:"flex-1 text-left"},[_vm._v(" "+_vm._s(_vm.motives.vacations[_vm.lang])+" ")]),(_vm.canCreateVersion.vacations)?_c('fw-tag',{attrs:{"type":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('button',{staticClass:"bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium",class:{
        'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !_vm.canCreateVersion.credit_days,
      },attrs:{"disabled":!_vm.canCreateVersion.credit_days},on:{"click":function($event){return _vm.createVersion('credit_days')}}},[_c('div',{staticClass:"flex-1 text-left"},[_c('p',[_vm._v(_vm._s(_vm.motives.credit_days[_vm.lang]))]),(!_vm.canCreateVersion.credit_days)?_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v("Não tem dias de crédito anual.")]):_vm._e()],1),(_vm.canCreateVersion.credit_days)?_c('fw-tag',{attrs:{"type":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('button',{staticClass:"bg-gray-100 rounded-lg hover:text-primary px-4 py-3 w-full justify-between gap-3 flex items-center group transition duration-150 font-medium",class:{
        'bg-gray-100 text-gray-400 hover:text-gray-400 focus:outline-none cursor-not-allowed': !_vm.canCreateVersion.accumulate_days,
      },attrs:{"disabled":!_vm.canCreateVersion.accumulate_days},on:{"click":function($event){return _vm.createVersion('accumulate_days')}}},[_c('div',{staticClass:"flex-1 text-left"},[_vm._v(" "+_vm._s(_vm.motives.accumulate_days[_vm.lang])+" "),(!_vm.canCreateVersion.accumulate_days)?_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v("A cumulação de dias para o ano "+_vm._s(_vm.mapYear)+" ainda não está disponível.")]):_vm._e()],1),(_vm.canCreateVersion.accumulate_days)?_c('fw-tag',{attrs:{"type":"primary"}},[_vm._v("Criar")]):_vm._e()],1)]),_c('div',{staticClass:"flex items-center justify-end gap-5 mt-5"},[_c('fw-button',{attrs:{"type":"link-muted"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")])],1),_c('fw-panel-info',{attrs:{"debug":"","label":"Data (raw)"}},[_c('json-viewer',{attrs:{"value":{ mapKey: _vm.mapKey, canCreateVersion: _vm.canCreateVersion }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }