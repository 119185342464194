var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex px-2 py-1.5 gap-3 my-0.5 relative w-full text-left group rounded-lg text-sm items-center",class:{
    'bg-white shadow-md': _vm.item.key == _vm.userMapKey,
    'bg-gray-300 bg-opacity-20 hover:text-gray-800': _vm.item.key !== _vm.userMapKey,
  },on:{"click":function($event){return _vm.$emit('open', _vm.item)}}},[_c('div',{staticClass:"w-full flex gap-2"},[_c('div',{staticStyle:{"min-width":"48px"}},[_c('fw-tag',{attrs:{"type":_vm.item.status === 'submitted' || _vm.item.status === 'approved'
            ? 'primary-border-box'
            : _vm.item.status === 'declined'
            ? 'danger-border-box'
            : _vm.item.key == _vm.latestApproved?.key
            ? 'primary'
            : 'light-border-box'}},[_c('span',{class:{
            'line-through': _vm.item.status === 'canceled',
          }},[_vm._v("v."+_vm._s(_vm.item.version || 'next'))]),(_vm.item.key === _vm.latestApproved?.key)?_c('fw-icon-star',{staticClass:"w-3 h-3 flex-shrink-0 text-primary ml-0.5 -mr-0.5"}):_vm._e()],1)],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-medium",class:{
          'text-yellow-600': _vm.item.status === 'draft',
        }},[_c('div',{class:{
            'line-through text-gray-500': _vm.item.status === 'canceled',
          }},[_vm._v(" "+_vm._s(_vm.versionsMotives[_vm.item.version_motive]?.['pt'])+" ")])]),_c('div',{staticClass:"flex gap-1 justify-between items-center"},[_c('div',{staticClass:"text-gray-500 text-xs py-0.5"},[(_vm.item.created_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.item.created_at))+" ")]):_vm._e()]),(_vm.item.version_motive !== 'sap_sync' && _vm.item.status !== 'canceled')?_c('div',{staticClass:"flex items-end"},[(_vm.item.status == 'submitted')?_c('fw-icon-send-plane',{staticClass:"text-primary w-4 h-4 flex-shrink-0"}):_vm._e(),(_vm.item.status == 'draft')?_c('fw-icon-edit',{staticClass:"text-yellow-600 w-4 h-4 flex-shrink-0"}):_vm._e(),(_vm.item.status == 'approved')?_c('span',{staticClass:"bg-gray-100 rounded-full"},[_c('fw-icon-checkbox-circle',{staticClass:"text-primary w-4 h-4 flex-shrink-0"})],1):_vm._e(),(_vm.item.pre_approved_at || _vm.item.status == 'approved')?_c('span',{staticClass:"bg-gray-100 rounded-full -ml-2"},[_c('fw-icon-checkbox-circle',{staticClass:"w-4 h-4 flex-shrink-0",class:{
                'text-gray-400': _vm.item.pre_approved_at && _vm.item.status != 'approved',
                'text-primary': _vm.item.status == 'approved',
              }})],1):_vm._e()],1):(_vm.item.status === 'outdated')?_c('div',[_c('fw-icon-archive',{staticClass:"w-4 h-4 text-gray-400 flex-shrink-0"})],1):_vm._e()])]),_c('fw-dot',{staticClass:"m-1",attrs:{"color":_vm.item.has_updates ? 'primary' : 'light',"ping":false}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }