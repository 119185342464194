<template>
  <button
    class="flex px-2 py-1.5 gap-3 my-0.5 relative w-full text-left group rounded-lg text-sm items-center"
    :class="{
      'bg-white shadow-md': item.key == userMapKey,
      'bg-gray-300 bg-opacity-20 hover:text-gray-800': item.key !== userMapKey,
    }"
    @click="$emit('open', item)"
  >
    <div class="w-full flex gap-2">
      <div style="min-width: 48px">
        <fw-tag
          :type="
            item.status === 'submitted' || item.status === 'approved'
              ? 'primary-border-box'
              : item.status === 'declined'
              ? 'danger-border-box'
              : item.key == latestApproved?.key
              ? 'primary'
              : 'light-border-box'
          "
        >
          <span
            :class="{
              'line-through': item.status === 'canceled',
            }"
            >v.{{ item.version || 'next' }}</span
          >
          <fw-icon-star
            v-if="item.key === latestApproved?.key"
            class="w-3 h-3 flex-shrink-0 text-primary ml-0.5 -mr-0.5"
          />
        </fw-tag>
      </div>
      <div class="flex-1">
        <div
          class="font-medium"
          :class="{
            'text-yellow-600': item.status === 'draft',
          }"
        >
          <div
            :class="{
              'line-through text-gray-500': item.status === 'canceled',
            }"
          >
            {{ versionsMotives[item.version_motive]?.['pt'] }}
          </div>
        </div>
        <div class="flex gap-1 justify-between items-center">
          <div class="text-gray-500 text-xs py-0.5">
            <div v-if="item.created_at">
              {{ item.created_at | formatDateTime }}
            </div>
          </div>
          <div v-if="item.version_motive !== 'sap_sync' && item.status !== 'canceled'" class="flex items-end">
            <fw-icon-send-plane v-if="item.status == 'submitted'" class="text-primary w-4 h-4 flex-shrink-0" />
            <fw-icon-edit v-if="item.status == 'draft'" class="text-yellow-600 w-4 h-4 flex-shrink-0" />
            <span v-if="item.status == 'approved'" class="bg-gray-100 rounded-full">
              <fw-icon-checkbox-circle class="text-primary w-4 h-4 flex-shrink-0" />
            </span>
            <span v-if="item.pre_approved_at || item.status == 'approved'" class="bg-gray-100 rounded-full -ml-2">
              <fw-icon-checkbox-circle
                class="w-4 h-4 flex-shrink-0"
                :class="{
                  'text-gray-400': item.pre_approved_at && item.status != 'approved',
                  'text-primary': item.status == 'approved',
                }"
              />
            </span>
          </div>
          <div v-else-if="item.status === 'outdated'">
            <fw-icon-archive class="w-4 h-4 text-gray-400 flex-shrink-0" />
          </div>
        </div>
      </div>
      <fw-dot :color="item.has_updates ? 'primary' : 'light'" :ping="false" class="m-1" />
    </div>
  </button>
</template>

<script>
import { MAP_USER_VERSION_REASONS } from '@/utils/index.js'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    userMapKey: {
      type: String,
      required: false,
    },
    latestApproved: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      versionsMotives: MAP_USER_VERSION_REASONS,
    }
  },
}
</script>
