<template>
  <div v-if="requiredRules?.length || contingentes?.length" class="border-b pb-3">
    <fw-label>Regras a cumprir</fw-label>
    <div class=" items-center text-sm">
      <div
        v-for="(rule, i) in requiredRules"
        :key="`rule_${i}`"
        class="group inline-flex gap-1 rounded py-1 w-full transition-colors duration-150"
      >
        <div :class="{ 'text-primary': rule.is_valid, 'text-gray-300': !rule.is_valid }">
          <fw-icon-checkbox-circle class="w-5 h-5 flex-shrink-0" />
        </div>
        <div class="group-opacity-100 font-medium text-gray-500">
          {{ rule.title }}
        </div>
      </div>
      <div
        v-for="(contingente, i) in allowedContingentes"
        :key="`contingente_${i}`"
        class="group inline-flex gap-1 rounded py-1 w-full transition-colors duration-150"
      >
        <div :class="{ 'text-primary': contingente.is_valid, 'text-gray-300': !contingente.is_valid }">
          <fw-icon-checkbox-circle class="w-5 h-5 flex-shrink-0" />
        </div>
        <div class="group-opacity-100 font-medium text-gray-500">
          <div v-if="contingente.number == 49">
            Dias do ano de contratação dedutíveis de
          </div>
          <div v-else-if="contingente.number == 50 && contingente.year == year - 1">
            Dias cumulados dedutíveis de
          </div>
          <div v-else-if="contingente.number == 50 && contingente.year == year">
            Dias dedutíveis de
          </div>
          <div>
            {{ contingente.deductible_from | formatDate }} a
            {{ contingente.deductible_until | formatDate }}
          </div>
          <div class="font-medium">{{ contingente.days }} dias disponíveis</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    periods: {
      type: Array,
      default: () => [],
    },
    contingentes: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
    },
  },

  computed: {
    requiredRules() {
      return this.periods?.filter(el => el.type == 'required') ?? []
    },
    allowedContingentes() {
      return this.contingentes?.filter(el => el.number == 49 || el.number == 50) ?? []
    },
  },
}
</script>
